<template>
    <div class="oggettoGenericoCard" v-if="card">
        <CardStandard :cover="cover" :titolo="titolo" :tassonomia="tassonomia" :link="link.address" :linkName="link.addressName" :isExternal="link.external" :imageTag="imageTag" @clickedLink="clickedLink" :width="width" :height="height" :mobileHeight="mobileHeight" />
    </div>
</template>

<script lang="ts" setup>
const { getCardCover, getCardTitolo, getCardTassonomia, getCardLink } = useCard()

interface Props {
    card: any,
    typename?: string | null,
    width?: number,
    height?: number,
    mobileHeight?: number | null,
}

const emit = defineEmits(['clickedLink'])
const props = defineProps<Props>()

const cover = computed(() => getCardCover(props.card))
const titolo = computed(() => getCardTitolo(props.card))
const tassonomia = computed(() => getCardTassonomia(props.card, props.typename))
const link = computed(() => getCardLink(props.card, props.typename))

const imageTag = computed(() => {
    if (props.card.scaduto === true || (props.card.item && props.card.item.scaduto === true)) return 'concluso'
    else return null
})

const clickedLink = () => {
    emit('clickedLink')
}
</script>